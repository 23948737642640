import React, { useMemo } from 'react';
import styled from 'styled-components';

import { InlineInputGroups, InputGroup, Select } from '~/components/shared/form';
import { ALL_OPTION, CLIENT, EPISODE, PLAN_TYPE } from '~/constants/filterKeysConstants';
import { GROUP_TYPE } from '~/constants/filterKeysConstants';
import { PROVIDER } from '~/constants/groupTypes';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { getDisplayName, getId, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';
import { Classification, Client } from '~/models';

interface ProgressTemplatesFilterBarProps {
  classifications: Classification[];
  clearFilters: () => void;
  clients: Client[];
  filters: Record<string, any>;
  optionsLoaded: boolean;
  setFilter: (filter: { filterType: string; value: any }) => void;
}

function ProgressTemplatesFilterBar({
  optionsLoaded,
  filters,
  clients,
  classifications,
  ...props
}: ProgressTemplatesFilterBarProps) {
  const episodeOptions = useMemo(() => [ALL_OPTION, ...classifications.filter((c) => c.isEpisode)], [classifications]);
  const planTypeOptions = useMemo(
    () => [ALL_OPTION, ...classifications.filter((c) => c.isPlanType)],
    [classifications]
  );
  const clientOptions = useMemo(() => [ALL_OPTION, ...clients], [clients]);

  const providerOptions = useAsyncOptions(fetchGroupTypes, {
    params: { sortBy: 'displayName asc', type: PROVIDER },
    optionsToPrepend: [ALL_OPTION],
  });

  const updateFilter = (key: string, val: any) => props.setFilter({ filterType: key, value: val });
  const handleOptionChange = (value: any, filterType: string) => {
    if (!optionsLoaded) return;

    updateFilter(filterType, value);
  };

  const showClearAll = Object.values(filters).some((value) => value.id);

  return (
    <FiltersContainer>
      <InputGroup
        label='Client'
        name={CLIENT}
        value={filters[CLIENT]}
        options={clientOptions}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={Select}
      />

      <InputGroup
        {...providerOptions}
        label='Managed Care Options'
        name={GROUP_TYPE}
        value={filters[GROUP_TYPE]}
        getOptionLabel={getDisplayName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={Select}
      />

      <InputGroup
        label='Episode Type'
        name={EPISODE}
        value={filters[EPISODE]}
        options={episodeOptions}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={Select}
      />

      <InputGroup
        label='Plan Type'
        name={PLAN_TYPE}
        value={filters[PLAN_TYPE]}
        options={planTypeOptions}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={Select}
      />

      {showClearAll && (
        <ClearLinkContainer>
          <ClearLink onClick={props.clearFilters}>Clear Filters</ClearLink>
        </ClearLinkContainer>
      )}
    </FiltersContainer>
  );
}

export default ProgressTemplatesFilterBar;

const FiltersContainer = styled(InlineInputGroups)`
  & > * {
    max-width: 230px;
    margin-bottom: 24px;
  }
`;

const ClearLinkContainer = styled.div`
  height: 40px;
  display: flex;
  flex: 0;
  align-items: center;
`;

const ClearLink = styled.div`
  cursor: pointer;
  width: 76px;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
