import React from 'react';
import styled from 'styled-components';

import { InlineInputGroups, InputGroup, Select } from '~/components/shared/form';
import { EPISODE as EPISODE_TYPE, PLAN_TYPE } from '~/constants/classifications';
import { ACUTE_CLIENT_TYPES } from '~/constants/clientTypes';
import { ALL_OPTION, CLIENT, EPISODE, GROUP_TYPE } from '~/constants/filterKeysConstants';
import { PROVIDER } from '~/constants/groupTypes';
import { fetchClassifications } from '~/ducks/admin/classifications';
import { fetchClients } from '~/ducks/admin/clients';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { getDisplayName, getId, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';

interface DischargeTemplatesFilterBarProps {
  clearFilters: () => void;
  filters: Record<string, any>;
  setFilter: (params: { filterType: string; value: any }) => void;
}

const DischargeTemplatesFilterBar: React.FC<DischargeTemplatesFilterBarProps> = ({ filters, ...props }) => {
  const updateFilter = (key: string, val: any) => props.setFilter({ filterType: key, value: val });
  const handleOptionChange = (value: any, filterType: string) => updateFilter(filterType, value);
  const showClearAll = Object.values(filters).some((value) => value.id);

  const clientsAsyncOptions = useAsyncOptions(fetchClients, {
    params: { clientType: ACUTE_CLIENT_TYPES.join(',') },
    optionsToPrepend: [ALL_OPTION],
  });

  const planTypeAsyncOptions = useAsyncOptions(fetchClassifications, {
    params: { type: PLAN_TYPE },
    optionsToPrepend: [ALL_OPTION],
  });

  const episodesAsyncOptions = useAsyncOptions(fetchClassifications, {
    params: { type: EPISODE_TYPE },
    optionsToPrepend: [ALL_OPTION],
  });

  const providerOptions = useAsyncOptions(fetchGroupTypes, {
    params: { sortBy: 'displayName asc', type: PROVIDER },
    optionsToPrepend: [ALL_OPTION],
  });

  return (
    <FiltersContainer>
      <InputGroup
        {...clientsAsyncOptions}
        label='Client'
        name={CLIENT}
        value={filters[CLIENT]}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={Select}
      />

      <InputGroup
        {...providerOptions}
        label='Managed Care Options'
        name={GROUP_TYPE}
        value={filters[GROUP_TYPE]}
        getOptionLabel={getDisplayName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={Select}
      />

      <InputGroup
        {...episodesAsyncOptions}
        label='Episode Type'
        name={EPISODE}
        value={filters[EPISODE]}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={Select}
      />

      <InputGroup
        {...planTypeAsyncOptions}
        label='Plan Type'
        name={PLAN_TYPE}
        value={filters[PLAN_TYPE]}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={Select}
      />

      {showClearAll && (
        <ClearLinkContainer>
          <ClearLink onClick={props.clearFilters}>Clear Filters</ClearLink>
        </ClearLinkContainer>
      )}
    </FiltersContainer>
  );
};

export default DischargeTemplatesFilterBar;

const FiltersContainer = styled(InlineInputGroups)`
  & > * {
    max-width: 230px;
    margin-bottom: 24px;
  }
`;

const ClearLinkContainer = styled.div`
  height: 40px;
  display: flex;
  flex: 0;
  align-items: center;
`;

const ClearLink = styled.div`
  cursor: pointer;
  width: 76px;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
