import { EPISODE, EPISODE_CLASS, PLAN_TYPE, PLAN_TYPE_CLASS } from '~/constants/classifications';

interface ClassificationOptions {
  id: string;
  name: string;
  type: string;
  clientIds: string[];
  clientNames: string[];
}

const defaults = {
  id: '',
  name: '',
  type: '',
  clientIds: [],
  clientNames: [],
};

export const classificationType = (type: string) => {
  switch (type) {
    case PLAN_TYPE_CLASS:
      return PLAN_TYPE;
    case EPISODE_CLASS:
      return EPISODE;
    default:
      return null;
  }
};

const classificationClass = (type: string | null) => {
  switch (type) {
    case PLAN_TYPE:
      return PLAN_TYPE_CLASS;
    case EPISODE:
      return EPISODE_CLASS;
    default:
      return null;
  }
};

export default class Classification {
  id: string;
  name: string;
  type: string | null;
  clientIds: string[];
  clientNames: string[];

  constructor(options: Partial<ClassificationOptions> = {}) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.name = opts.name;
    this.type = classificationType(opts.type);
    this.clientIds = opts.clientIds;
    this.clientNames = opts.clientNames;
  }

  get isPlanType() {
    return this.type === PLAN_TYPE;
  }

  get isEpisode() {
    return this.type === EPISODE;
  }

  serialize() {
    return {
      id: this.id,
      name: this.name,
      type: classificationClass(this.type),
    };
  }
}
