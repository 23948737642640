export const IMPORTED_PATIENT = 'imported_patient';

export const DELIMITER_OPTS = [
  { label: 'Comma Separated', value: ',' },
  { label: 'Pipe Separated', value: '|' },
  { label: 'Tab Separated', value: '\t' },
];

export const PATIENT_MAPPINGS = [
  { label: 'Patient Name', key: 'name' },
  { label: 'Patient ID', key: 'externalId' },
  { label: 'Date of Birth', key: 'dateOfBirth' },
  { label: 'Gender', key: 'sex' },
  { label: 'Hospital Admission Date', key: 'hospitalAdmissionDate', optional: true },
];
