import { DELIMITER_OPTS, PATIENT_MAPPINGS } from '~/constants/importConfigurations';
import snakeCase from '~/helpers/snakeCase';

import Classification from './Classification';
import Client from './Client';
import Group from './Group';
import GroupType from './GroupType';

export class ClassificationType {
  apiName: string;
  id: string | null;
  name: string;

  constructor(options: Partial<ClassificationType>) {
    this.apiName = options.apiName || '';
    this.id = options.id || null;
    this.name = options.name || '';
  }
}

interface PropertyConfig {
  importKey: string;
  olioDefault: string;
}

export interface Mapping<MappingType, DefaultValueType> {
  columnName: string;
  type: MappingType | null;
  defaultValue: DefaultValueType | null;
}
interface ImportConfigurationOptions {
  id: string;
  client: Client;
  clientId: string;
  config: {
    dateFormat: string;
    delimiter: string | { label: string; value: string };
    properties: {
      [key: string]: string | PropertyConfig;
      episodeClassification: string | PropertyConfig;
      planTypeClassification: string | PropertyConfig;
    };
    mappings: {
      classifications: Mapping<ClassificationType, Classification>[];
      groups: Mapping<GroupType, Group>[];
    };
  };
  type: string;
  updatedAt: string;
}

const defaults: ImportConfigurationOptions = {
  id: '',
  client: new Client(),
  clientId: '',
  config: {
    dateFormat: '%m/%d/%Y',
    delimiter: DELIMITER_OPTS[0].value,
    properties: {
      episodeClassification: '',
      planTypeClassification: '',
    },
    mappings: {
      classifications: [],
      groups: [],
    },
  },
  type: '',
  updatedAt: new Date().toISOString(),
};

export default class ImportConfiguration {
  id: string;
  client: Client;
  clientId: string;
  config: {
    dateFormat: string;
    delimiter: string | { label: string; value: string };
    properties: {
      [key: string]: string | PropertyConfig;
      episodeClassification: string | PropertyConfig;
      planTypeClassification: string | PropertyConfig;
    };
    mappings: {
      classifications: Mapping<ClassificationType, Classification>[];
      groups: Mapping<GroupType, Group>[];
    };
  };
  type: string;
  updatedAt: string;

  constructor(options: Partial<ImportConfigurationOptions> = defaults) {
    const opts = {
      ...defaults,
      ...options,
      config: {
        ...defaults.config,
        ...options?.config,
      },
    };

    this.id = opts.id;
    this.client = new Client(opts.client);
    this.clientId = opts.clientId;
    this.config = opts.config;
    this.type = opts.type;
    this.updatedAt = opts.updatedAt;
  }

  get clientName() {
    return this.client.name;
  }

  get clientType() {
    return this.client.clientType;
  }

  toFormValues() {
    return {
      ...this,
      config: {
        ...this.config,
        dateFormat: this.config.dateFormat || '%m/%d/%Y',
        delimiter: DELIMITER_OPTS.find((opt) => opt.value === this.config.delimiter),
      },
    };
  }

  serialize() {
    const result = {
      id: this.id,
      clientId: this.client.id,
      config: {
        ...this.config,
        delimiter: typeof this.config.delimiter === 'string' ? this.config.delimiter : this.config.delimiter.value,
        // Back compat logic to populate "properties" key that will be phased out in OLIO-254
        properties: {
          ...this.serializeBaseProperties(),
          ...this.serializeClassificationProperties(),
          ...this.serializeGroupProperties(),
        },
        mappings: this.serializeMappings(),
      },
      type: this.type,
    };

    return result;
  }

  serializeMappings() {
    return {
      ...this.config.mappings,
      classifications: this.config.mappings.classifications.map(this.serializeMapping),
      groups: this.config.mappings.groups.map(this.serializeMapping),
    };
  }

  serializeMapping(mapping: Mapping<ClassificationType, Classification> | Mapping<GroupType, Group>) {
    const defaultValue = mapping.defaultValue;
    const type = mapping.type;

    return {
      columnName: mapping.columnName,
      type: { apiName: type?.apiName, id: type?.id },
      defaultValue: defaultValue ? { id: defaultValue.id } : null,
    };
  }

  // The following "serialize*Properties" functions are back compat logic to populate "properties"
  // key that will be phased out in OLIO-254
  serializeBaseProperties() {
    return Object.fromEntries(
      Object.entries(this.config.mappings).filter((entry) => {
        return PATIENT_MAPPINGS.map(({ key }) => key).includes(entry[0]);
      })
    );
  }

  serializeClassificationProperties() {
    return this.config.mappings.classifications.reduce(
      (acc, mapping) => {
        if (!mapping.type) return acc;

        const apiName = `${snakeCase(mapping.type.apiName)}_classification`;

        const defaultValue = mapping.defaultValue
          ? { importKey: mapping.columnName || apiName, olioDefault: mapping.defaultValue.name }
          : mapping.columnName;

        if (defaultValue) {
          acc[apiName] = defaultValue;
        }

        return acc;
      },
      {} as { [key: string]: string | PropertyConfig }
    );
  }

  serializeGroupProperties() {
    return this.config.mappings.groups.reduce(
      (acc, mapping) => {
        if (!mapping.type) return acc;

        const defaultValue = mapping.defaultValue;
        const groupValue = defaultValue
          ? { importKey: mapping.columnName || mapping.type.apiName, olioDefault: defaultValue.name }
          : mapping.columnName;

        // Back compat logic to populate the "group" key that will be phased out in OLIO-254
        if (mapping.type.apiName === 'hospital') {
          acc['group'] = groupValue;
        } else {
          acc[snakeCase(mapping.type.apiName)] = groupValue;
        }

        return acc;
      },
      {} as { [key: string]: string | PropertyConfig }
    );
  }
}
