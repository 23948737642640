import React from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import ClientsFlyoutMenu, { permissions } from '~/components/clients/ClientsFlyoutMenu';
import { ParentLabel } from '~/components/shared/table';
import { BodySmallBold } from '~/components/shared/typography';
import { PAYOR } from '~/constants/clientTypes';
import { Client } from '~/models';
import { ProfileService } from '~/services/profile';

const columnHelper = createColumnHelper<Client>();

type ColumnFnProps = {
  profileSvc: ProfileService;
} & Omit<React.ComponentProps<typeof ClientsFlyoutMenu>, 'row'>;

export default function clientsTableColumns({ profileSvc, ...clientsFlyoutMenuProps }: ColumnFnProps) {
  const columns: ColumnDef<Client, any>[] = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Client Name',
      cell: (info) => (
        <React.Fragment>
          <BodySmallBold>{info.getValue()}</BodySmallBold>
          {info.row.original.isParent && <ParentLabel />}
        </React.Fragment>
      ),
    }),
    columnHelper.accessor((row) => row.clientType, {
      id: 'clientType',
      header: 'Client Type',
      cell: (info) => (info.getValue() === PAYOR ? 'Payer' : info.getValue()),
    }),
    columnHelper.accessor((row) => row.managedProviderTypes, {
      id: 'managedProviderTypes',
      header: 'Managed Care Options',
      cell: (info) =>
        info.getValue()?.length ? (
          info
            .getValue()
            .map((x: any) => x.displayName)
            .join(', ')
        ) : (
          <>&mdash;</>
        ),
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.ssoEnabled, {
      id: 'ssoEnabled',
      header: 'SSO Enabled',
      cell: (info) => (info.getValue() ? 'Yes' : 'No'),
    }),
    columnHelper.accessor((row) => row.mfaEnabled, {
      id: 'mfaEnabled',
      header: 'MFA Enabled',
      cell: (info) => (info.getValue() ? 'Yes' : 'No'),
    }),
  ];

  if (profileSvc.hasAny(permissions)) {
    columns.push(
      columnHelper.display({
        id: 'menu',
        cell: (info) => <ClientsFlyoutMenu {...clientsFlyoutMenuProps} row={info.row} />,
        size: 75,
        meta: { style: { justifyContent: 'flex-end' } },
      })
    );
  }

  return columns;
}
