import React from 'react';

import { Row } from '@tanstack/react-table';

import { FlyoutMenu, FlyoutMenuIcon, FlyoutMenuItem } from '~/components/shared/FlyoutMenu';
import { CollaborationsIcon, PencilIcon } from '~/components/shared/svg';
import ConfigureCareIcon from '~/components/shared/svg/ConfigureCareIcon';
import { Client } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';

export const { adminClientEdit, adminClientGroupTypeView, adminCollaborationEdit } = PERMISSIONS;
export const permissions = [adminClientEdit, adminClientGroupTypeView, adminCollaborationEdit];

type Props = {
  onCollaborationEdit: (client: Client) => void;
  onEdit: (client: Client) => void;
  onEditClientGroupTypes: (client: Client) => void;
  row: Row<Client>;
};

function ClientsFlyoutMenu(props: Props) {
  const { onCollaborationEdit, onEdit, onEditClientGroupTypes, row } = props;

  const profileSvc = useProfileContext();

  const configureCareOptionsIsVisible =
    row.original.managedProviderTypes?.length && profileSvc.has(adminClientGroupTypeView);

  return (
    <FlyoutMenu data={row.original}>
      <FlyoutMenuItem onClick={onEdit} visible={profileSvc.has(adminClientEdit)}>
        <FlyoutMenuIcon>
          <PencilIcon size={18} />
        </FlyoutMenuIcon>
        Edit
      </FlyoutMenuItem>
      <FlyoutMenuItem onClick={onCollaborationEdit} visible={profileSvc.has(adminCollaborationEdit)}>
        <FlyoutMenuIcon>
          <CollaborationsIcon />
        </FlyoutMenuIcon>
        Collaborations
      </FlyoutMenuItem>
      <FlyoutMenuItem onClick={onEditClientGroupTypes} visible={!!configureCareOptionsIsVisible}>
        <FlyoutMenuIcon>
          <ConfigureCareIcon size={18} />
        </FlyoutMenuIcon>
        Configure Managed Care Options
      </FlyoutMenuItem>
    </FlyoutMenu>
  );
}

export default ClientsFlyoutMenu;
