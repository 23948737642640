import * as Yup from 'yup';

export const importConfigurationValidation = Yup.object().shape({
  client: Yup.object()
    .shape({
      id: Yup.string(),
      name: Yup.string(),
    })
    .nullable()
    .test('client', 'Client is required', (obj) => Boolean(obj && obj.id)),
  config: Yup.object()
    .shape({
      dateFormat: Yup.string().required('Date Format is required'),
      delimiter: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .test('delimiter', 'Delimiter is required', ({ value }) => !!value),
      mappings: Yup.object().shape({
        dateOfBirth: Yup.string().required('Date of Birth is required'),
        externalId: Yup.string().required('Patient ID is required'),
        name: Yup.string().required('Patient Name is required'),
        sex: Yup.string().required('Gender is required'),
        classifications: Yup.array()
          .of(
            Yup.object()
              .shape({
                columnName: Yup.string().nullable().label('Client Header'),
                defaultValue: Yup.object()
                  .shape({
                    id: Yup.string(),
                    name: Yup.string(),
                  })
                  .nullable()
                  .label('Default Value'),
                type: Yup.object()
                  .shape({
                    apiName: Yup.string(),
                    name: Yup.string(),
                  })
                  .required('Classification Type is required'),
              })
              .test('columnNameOrDefaultValue', 'Either Client Header or Default Value is required', function (value) {
                return value.columnName || value.defaultValue;
              })
          )
          .test(
            'onlyOneMappingPerClassificationType',
            'Only one mapping per Classification Type can exist',
            function (value) {
              const apiNames = value.map((item) => item.type?.apiName);

              return apiNames.length === new Set(apiNames).size;
            }
          ),
        groups: Yup.array()
          .of(
            Yup.object()
              .shape({
                columnName: Yup.string().nullable().label('Client Header'),
                defaultValue: Yup.object()
                  .shape({
                    id: Yup.string(),
                    name: Yup.string(),
                  })
                  .nullable()
                  .label('Default Value'),
                type: Yup.object()
                  .shape({
                    apiName: Yup.string(),
                    name: Yup.string(),
                  })
                  .required('Group Type is required'),
              })
              .test('columnNameOrDefaultValue', 'Either Client Header or Default Value is required', function (value) {
                return value.columnName || value.defaultValue;
              })
          )
          .test('onlyOneMappingPerGroupType', 'Only one mapping per Group Type can exist', function (value) {
            const apiNames = value.map((item) => item.type?.apiName);

            return apiNames.length === new Set(apiNames).size;
          }),
      }),
    })
    .nullable(),
});
