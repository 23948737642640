import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { getClientTypeName } from '~/components/clients/helpers';
import { Checkbox, CheckboxMultiSelect, InlineInputGroups, InputGroup, Select } from '~/components/shared/form';
import Search from '~/components/shared/Search';
import { CLIENT_TYPES } from '~/constants/clientTypes';
import {
  ALL_OPTION,
  CLIENT_TYPE,
  MANAGED_PROVIDER_TYPE,
  MFA_ENABLED,
  PROVIDED_PROVIDER_TYPE,
  SEARCH,
  SSO_ENABLED,
} from '~/constants/filterKeysConstants';
import { PROVIDER } from '~/constants/groupTypes';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { clearFilters, getClientsFilters, setFilter } from '~/ducks/clientsFilters';
import { getDisplayName, getId } from '~/helpers';
import { useAsyncOptions, useDebounce } from '~/lib/hooks';

const mapStateToProps = (state: any) => ({
  filters: getClientsFilters(state),
});

const mapDispatchToProps = {
  clearFilters,
  setFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ClientsFilterBarProps = ConnectedProps<typeof connector>;

function ClientsFilterBar({ filters, ...props }: ClientsFilterBarProps) {
  const [search, setSearch] = useState(filters[SEARCH]);
  const debouncedSearch = useDebounce(search);
  const clientTypeOptions = useMemo(() => {
    return [ALL_OPTION, ...CLIENT_TYPES.map((value) => ({ id: value, name: value }))];
  }, []);
  const providerAsyncOptions = useAsyncOptions(fetchGroupTypes, {
    params: { sortBy: 'displayName asc', type: PROVIDER },
  });

  const showSSOEnabled = filters[SSO_ENABLED];
  const showMfaEnabled = filters[MFA_ENABLED];

  const updateFilter = (key: string, val: any) => props.setFilter({ filterType: key, value: val });

  useEffect(() => {
    updateFilter(SEARCH, search);
  }, [debouncedSearch]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value);
  const handleOptionChange = (value: any, filterType: string) => {
    updateFilter(filterType, value);
  };
  const handleSearchClear = () => setSearch('');
  const handleClearFilters = () => props.clearFilters();

  const showClearAll = Object.values(filters).some((value: any) => value.id) || showSSOEnabled || showMfaEnabled;

  return (
    <FiltersContainer>
      <StyledSearch
        value={search}
        placeholder='Search Clients'
        onChange={handleSearchChange}
        onClear={handleSearchClear}
      />

      <InputGroup
        label='Client Type'
        name={CLIENT_TYPE}
        value={filters[CLIENT_TYPE]}
        options={clientTypeOptions}
        getOptionLabel={getClientTypeName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={Select}
      />

      <InputGroup
        {...providerAsyncOptions}
        label='Managed Care Options'
        name={MANAGED_PROVIDER_TYPE}
        placeholder={'Select'}
        value={filters[MANAGED_PROVIDER_TYPE]}
        getOptionLabel={getDisplayName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={CheckboxMultiSelect}
      />

      <InputGroup
        {...providerAsyncOptions}
        label='Provided Care Options'
        name={PROVIDED_PROVIDER_TYPE}
        placeholder={'Select'}
        value={filters[PROVIDED_PROVIDER_TYPE]}
        getOptionLabel={getDisplayName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={CheckboxMultiSelect}
      />

      <CheckBoxWrapper>
        <CheckboxContainer>
          <Checkbox
            label='SSO Enabled'
            name={SSO_ENABLED}
            value={showSSOEnabled}
            size={18}
            labelSize='16px'
            onChange={handleOptionChange}
          />
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox
            label='MFA Enabled'
            name={MFA_ENABLED}
            value={showMfaEnabled}
            size={18}
            labelSize='16px'
            onChange={handleOptionChange}
          />
        </CheckboxContainer>
      </CheckBoxWrapper>

      {showClearAll && (
        <ClearLinkContainer>
          <ClearLink onClick={handleClearFilters}>Clear Filters</ClearLink>
        </ClearLinkContainer>
      )}
    </FiltersContainer>
  );
}

export default connector(ClientsFilterBar);

const FiltersContainer = styled(InlineInputGroups)`
  & > * {
    max-width: 230px;
    margin-bottom: 24px;
  }
`;

const StyledSearch = styled(Search)`
  margin-right: 24px;
`;

const CheckboxContainer = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: auto;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  flex: 0 1 auto;

  ${CheckboxContainer} {
    margin-left: 16px;
  }
`;

const ClearLinkContainer = styled.div`
  height: 40px;
  display: flex;
  flex: 0;
  align-items: center;
`;

const ClearLink = styled.div`
  cursor: pointer;
  width: 76px;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
