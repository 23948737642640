import React, { Fragment } from 'react';
import { ErrorMessage, FieldArray } from 'formik';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import {
  ErrorMessage as Error,
  Form,
  FormHeader,
  FormSection,
  Input,
  InputGroup,
  SectionHeader,
  Select,
} from '~/components/shared/form';
import JSONEditor from '~/components/shared/form/JSONEditor';
import { ArrowIcon, FormIndent } from '~/components/shared/svg';
import TrashIcon from '~/components/shared/svg/TrashIcon';
import { DELIMITER_OPTS, PATIENT_MAPPINGS } from '~/constants/importConfigurations';
import { fetchClassifications } from '~/ducks/admin/classifications';
import { fetchClients } from '~/ducks/admin/clients';
import { fetchGroups } from '~/ducks/admin/groups';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { getApiName, getDisplayName, getId, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';
import { Client, Group, GroupType } from '~/models';
import Classification from '~/models/Classification';
import { ClassificationType, Mapping } from '~/models/ImportConfiguration';
import { colors } from '~/styles/theme';

interface FormValues {
  client?: Client;
  config: {
    dateFormat?: string;
    delimiter?: string;
    properties?: {
      dateOfBirth: string;
      episodeClassification: string;
      hospitalAdmissionDate: string;
      name: string;
      planTypeClassification: string;
      sex: string;
      [key: string]:
        | string
        | {
            importKey: string;
            olioDefault: string;
          };
    };
    mappings: {
      classifications: Mapping<ClassificationType, Classification>[];
      groups: Mapping<GroupType, Group>[];
    };
  };
  id?: string;
}

interface ImportConfigurationFormProps {
  dirty: boolean;
  errors: {
    config?: {
      mappings?: {
        classifications?: string;
        groups?: string;
      };
    };
  };
  isSubmitting: boolean;
  isValid: boolean;
  onCancel: () => void;
  setFieldValue: <T>(field: string, value: T, shouldValidate?: boolean) => void;
  setValues: (values: FormValues, shouldValidate?: boolean) => void;
  values: FormValues;
}

function ImportConfigurationForm(props: ImportConfigurationFormProps) {
  const { dirty, errors, isValid, isSubmitting, onCancel, values } = props;

  const classificationTypeOptions = [
    new ClassificationType({ name: 'Episode Type', apiName: 'episode' }),
    new ClassificationType({ name: 'Plan Type', apiName: 'plan_type' }),
  ];

  const clientsAsyncOptions = useAsyncOptions(fetchClients, {
    params: {
      sortBy: 'name asc',
      hasImportConfiguration: false,
    },
  });

  const groupTypeAsyncOptions = useAsyncOptions(fetchGroupTypes, {
    params: {
      sortBy: 'displayName asc',
    },
  });

  const [focusedClassificationType, setFocusedClassificationType] = React.useState<ClassificationType | null>(
    values.config.mappings.classifications[0]?.type
  );
  const classificationAsyncOptions = useAsyncOptions(fetchClassifications, {
    condition: !!focusedClassificationType?.apiName,
    params: {
      client: values.client?.id,
      sortBy: 'name asc',
      type: focusedClassificationType?.apiName,
    },
  });

  const [focusedGroupType, setFocusedGroupType] = React.useState<GroupType | null>(
    values.config.mappings.groups[0]?.type
  );
  const groupAsyncOptions = useAsyncOptions(fetchGroups, {
    condition: !!focusedGroupType?.id,
    params: { clientIdOrNetworkClientId: values.client?.id, sortBy: 'name asc', groupType: focusedGroupType?.id },
  });

  const enum MappingType {
    Classification,
    Group,
  }
  const handleAddAnotherMapping = (mappingType: MappingType) => {
    let mapping;

    if (mappingType === MappingType.Classification) {
      mapping = values.config.mappings.classifications;
    } else {
      mapping = values.config.mappings.groups;
    }

    mapping.push({ defaultValue: null, type: null, columnName: '' });
    props.setValues(values);
  };

  const handleRemoveMapping = (mappingType: MappingType, index: number) => {
    let mapping;

    if (mappingType === MappingType.Classification) {
      mapping = values.config.mappings.classifications;
    } else {
      mapping = values.config.mappings.groups;
    }

    mapping.splice(index, 1);
    props.setValues(values);
  };

  const getGroupTypeLabel = (groupType: GroupType) => {
    return `${groupType.name} (${groupType.type.displayName})`;
  };

  return (
    <Form>
      <FormHeader title={values.id ? 'Edit Import Configuration' : 'Add Import Configuration'} />
      <FormSection>
        <InputGroup
          {...clientsAsyncOptions}
          name='client'
          label='Client'
          placeholder='Client'
          disabled={!!values.id}
          getOptionLabel={getName}
          getOptionValue={getId}
          component={Select}
        />
      </FormSection>

      <FormSection>
        <SectionHeader>File Format</SectionHeader>
        <InputGroup label='Delimiter' name='config.delimiter' options={DELIMITER_OPTS} component={Select} />
        <InputGroup name='config.dateFormat' label='Date Format' placeholder='Date Format' component={Input} />
      </FormSection>

      <FormSection>
        <SectionHeader>Patient Demographic Mappings</SectionHeader>
        <FieldArray
          name='config.mappings'
          render={() => (
            <Fragment>
              {PATIENT_MAPPINGS.map(({ key, label, optional }, index) => (
                <MappingRow key={index}>
                  <StyledInputGroup
                    name={`config.mappings[${key}]`}
                    label={`Client Header ${optional ? '(optional)' : ''}`}
                    component={Input}
                  />
                  <IconContainer>
                    <ArrowIcon />
                  </IconContainer>
                  <StyledInputGroup name={key} label='Olio Field' component={Input} value={label} disabled />
                </MappingRow>
              ))}
            </Fragment>
          )}
        />
        <SectionHeader>Classification Mappings</SectionHeader>
        {values.config.mappings.classifications.map((classification, index) => {
          const { type } = classification;
          const selectedClassificationType = classificationTypeOptions.find(
            (option) => option.apiName === type?.apiName
          );

          return (
            <MappingRowWithSubRow key={`${type?.apiName}-${index}`}>
              <MappingRow>
                <StyledInputGroup
                  name={`config.mappings.classifications[${index}].columnName`}
                  label='Client Header (optional)'
                  component={Input}
                  disabled={!type}
                />
                <IconContainer>
                  <ArrowIcon />
                </IconContainer>
                <StyledInputGroup
                  options={classificationTypeOptions}
                  name={`config.mappings.classifications[${index}].type`}
                  label='Classification Type'
                  placeholder='Classification'
                  getOptionLabel={getName}
                  getOptionValue={getApiName}
                  component={Select}
                  value={selectedClassificationType}
                />
                <IconContainer width='36px'>
                  <StyledTrashIcon onClick={() => handleRemoveMapping(MappingType.Classification, index)} />
                </IconContainer>
              </MappingRow>
              {typeof errors.config?.mappings?.classifications === 'object' && (
                <ErrorMessage name={`config.mappings.classifications[${index}]`} component={Error} />
              )}
              <MappingRow>
                <StyledFormIndent />
                <InputGroup
                  {...classificationAsyncOptions}
                  name={`config.mappings.classifications[${index}].defaultValue`}
                  label='Default Value (optional)'
                  getOptionLabel={getName}
                  getOptionValue={getId}
                  component={Select}
                  onFocus={() => setFocusedClassificationType(values.config.mappings.classifications[index].type)}
                  autoSelectSingleOption={false}
                  isClearable
                  disabled={!type}
                />
              </MappingRow>
            </MappingRowWithSubRow>
          );
        })}
        <ErrorMessage name={'config.mappings.classifications'} component={Error} />
        <Link onClick={() => handleAddAnotherMapping(MappingType.Classification)}>
          Add {values.config.mappings.classifications.length > 0 ? 'another' : 'a'} classification mapping
        </Link>
        <SectionHeader>Group Mappings</SectionHeader>
        {values.config.mappings.groups.map((group, index) => {
          const { type } = group;

          return (
            <MappingRowWithSubRow key={`${type?.apiName}-${index}`}>
              <MappingRow>
                <StyledInputGroup
                  name={`config.mappings.groups[${index}].columnName`}
                  label='Client Header (optional)'
                  component={Input}
                  disabled={!type}
                />
                <IconContainer>
                  <ArrowIcon />
                </IconContainer>
                <StyledInputGroup
                  {...groupTypeAsyncOptions}
                  name={`config.mappings.groups[${index}].type`}
                  label='Group Type'
                  placeholder='Group Type'
                  getOptionLabel={getGroupTypeLabel}
                  getOptionValue={getApiName}
                  component={Select}
                />
                <IconContainer width='36px'>
                  <StyledTrashIcon onClick={() => handleRemoveMapping(MappingType.Group, index)} />
                </IconContainer>
              </MappingRow>
              {typeof errors.config?.mappings?.groups === 'object' && (
                <ErrorMessage name={`config.mappings.groups[${index}]`} component={Error} />
              )}
              <MappingRow>
                <StyledFormIndent />
                <InputGroup
                  {...groupAsyncOptions}
                  name={`config.mappings.groups[${index}].defaultValue`}
                  label='Default Value (optional)'
                  getOptionLabel={getName}
                  getOptionValue={getId}
                  component={Select}
                  onFocus={() => setFocusedGroupType(values.config.mappings.groups[index].type)}
                  autoSelectSingleOption={false}
                  isClearable
                  disabled={!type}
                />
              </MappingRow>
            </MappingRowWithSubRow>
          );
        })}
        <ErrorMessage name={'config.mappings.groups'} component={Error} />
        <Link onClick={() => handleAddAnotherMapping(MappingType.Group)}>
          Add {values.config.mappings.groups.length > 0 ? 'another' : 'a'} group mapping
        </Link>
      </FormSection>

      <ButtonGroup>
        <Button color='transparent' text='Cancel' onClick={onCancel} />
        <Button type='submit' disabled={!dirty || !isValid || isSubmitting} text='Submit' />
      </ButtonGroup>

      <StyledFormSection>
        <SectionHeader>Raw Config</SectionHeader>
        <InputGroup component={StyledJSONEditor} disabled name='rawConfig' />
      </StyledFormSection>
    </Form>
  );
}

export default ImportConfigurationForm;

const MappingRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const MappingRowWithSubRow = styled(MappingRow)`
  flex-direction: column;

  & > :last-child {
    align-self: flex-end;
    margin-top: -16px;
    width: 40%;
  }
`;

const StyledInputGroup: typeof InputGroup = styled(InputGroup)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledFormIndent = styled(FormIndent)`
  margin-right: 8px;
`;

const IconContainer = styled.div<{ width?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '60px'};
`;

const StyledJSONEditor = styled(JSONEditor)`
  max-height: inherit;
`;

const StyledFormSection = styled(FormSection)`
  margin-top: 48px;
`;

export const Link = styled.a`
  color: ${colors.primaryBlue};
  cursor: pointer;
  display: flex;
  margin-bottom: 24px;
`;

const StyledTrashIcon = styled(TrashIcon)`
  color: ${colors.accentRed};
  cursor: pointer;
`;
