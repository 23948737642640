import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { MainPage } from '~/components/shared/pageLayout';
import { Table } from '~/components/shared/table';
import { fetchClients } from '~/ducks/admin/clients';
import { useThunk } from '~/lib/hooks';
import { Flag } from '~/models';
import { useProfileContext } from '~/services/profile';
import adminFlagsAPI from '~/sources/api/adminFlagsAPI';

import featureFlagsTableColumns from './featureFlagsTableColumns';
import { parseFlagsData } from './flagsHelpers';

function FeatureFlags() {
  const profileSvc = useProfileContext();
  const [flags, setFlags] = useState([]);
  const navigate = useNavigate();
  const { data: clients } = useThunk(fetchClients, []);

  const fetchFlags = async () => {
    const response = await adminFlagsAPI.fetchFlags();

    setFlags(response.data.data);
  };

  useEffect(() => {
    fetchFlags();
  }, []);

  const onEdit = (flag: Flag) => {
    navigate(`/feature-flags/${flag.name}/edit`);
  };

  const parsedFeatures = useMemo(() => parseFlagsData({ flags, clients }), [clients, flags]);
  const columns = useMemo(
    () =>
      featureFlagsTableColumns({
        profileSvc,
        onEdit,
      }),
    []
  );

  return (
    <MainPage title='Feature Flags'>
      <Table data={parsedFeatures ?? []} columns={columns} paginated={false} loading={!parsedFeatures} />
    </MainPage>
  );
}

export default FeatureFlags;
