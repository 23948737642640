import * as locationTypes from '~/constants/locationTypes';

export const ACTIVE = 'active';
export const ALL = 'All';
export const ALL_OPTION: any = { id: 0, displayName: ALL, name: ALL };
export const ATTR = 'attr';
export const ATTR_DISPLAY_NAME = 'attrDisplayName';
export const CASE_MANAGER = 'caseManager';
export const CLIENT = 'client';
export const CLIENT_TYPE = 'clientType';
export const CREATED_AT = 'createdAt';
export const DATES = 'dates';
export const DAYS_NOT_TRACKED = 'daysNotTracked';
export const DISCHARGED_TO_GROUP_TYPE = 'dischargedToGroupType';
export const DOC_TYPE = 'docType';
export const END_DATE = 'endDate';
export const ENABLED_PROVIDER_TYPE = 'enabledProviderType';
export const EPISODE = 'episode';
export const EPISODE_CLASSIFICATIONS = 'episodeClassification';
export const GROUP = 'group';
export const GROUP_TYPE = 'groupType';
export const PROVIDED_PROVIDER_TYPE = 'providedProviderType';
export const MANAGED_PROVIDER_TYPE = 'managedProviderType';
export const HEALTH_SYSTEM = 'healthSystem';
export const HOME_HEALTH_AGENCY = 'homeHealthAgency';
export const HOSPITALS = 'hospital';
export const INPATIENT = 'inpatient';
export const INPATIENT_REHAB_FACILITY = 'inpatientRehabFacility';
export const LATEST_REHAB_STATE = 'latestRehabState';
export const LOCATION_TYPE = 'locationType';
export const LONG_TERM_ACUTE_CARE_HOSPITAL = 'longTermAcuteCareHospital';
export const MAPPED_OLIO_FIELD = 'olioAttr';
export const NONE = 'None';
export const NONE_OPTION = { id: 'null', name: NONE };
export const ONLY_INACTIVE = 'onlyInactive';
export const PATIENT_STATE = 'patientState';
export const PAYERS = 'payer';
export const PHYSICIAN_GROUP = 'physicianGroup';
export const PHYSICIAN_TEAM = 'physicianTeam';
export const PLAN_TYPE = 'planType';
export const PLAN_TYPE_CLASSIFICATIONS = 'planTypeClassification';
export const REHAB_FACILITIES = 'rehabFacility'; // TODO: remove `REHAB_FACILITIES` in Insights 2.0 cleanup #187229995
export const ROLE = 'role';
export const SEARCH = 'search';
export const SORTS = 'sorts';
export const START_DATE = 'startDate';
export const STATUS = 'status';
export const SKILLED_NURSING_FACILITY = 'skilledNursingFacility';
export const SSO_ENABLED = 'ssoEnabled';
export const MFA_ENABLED = 'mfaEnabled';
export const TYPE = 'type';
export const UM_ENABLED = 'umEnabled';

// TODO: remove `ALL_REHAB_FACILITIES` in Insights 2.0 cleanup #187229995
export const ALL_REHAB_FACILITIES = [
  SKILLED_NURSING_FACILITY,
  HOME_HEALTH_AGENCY,
  INPATIENT_REHAB_FACILITY,
  LONG_TERM_ACUTE_CARE_HOSPITAL,
];

export const FILTER_KEY_LOCATION_TYPES = {
  [SKILLED_NURSING_FACILITY]: locationTypes.SKILLED_NURSING_FACILITY,
  [HOME_HEALTH_AGENCY]: locationTypes.HOME_HEALTH_AGENCY,
  [LONG_TERM_ACUTE_CARE_HOSPITAL]: locationTypes.LONG_TERM_ACUTE_CARE_HOSPITAL,
  [INPATIENT_REHAB_FACILITY]: locationTypes.INPATIENT_REHAB_FACILITY,
};

export enum PatientState {
  CURRENT = 'current',
  CONTINUED = 'continued',
  LATEST = 'latest',
}

export const FLAGGED_OLIO_ATTRS = [PHYSICIAN_TEAM];

export const filterKeysToWords: Record<string, string> = {
  [PLAN_TYPE_CLASSIFICATIONS]: 'Plan Type',
  [EPISODE_CLASSIFICATIONS]: 'Episode Type',
};
