const getProp = (obj, path) => {
  const props = path.split('.');
  const getObjProp = (object, property) => object?.[property];

  return props.reduce((acc, prop) => getObjProp(acc, prop), obj);
};

export const getId = (obj) => getProp(obj, 'id');
export const getName = (obj) => getProp(obj, 'name');
export const getFullName = (obj) => getProp(obj, 'fullName');
export const getDisplayName = (obj) => getProp(obj, 'displayName');
export const getKind = (obj) => getProp(obj, 'kind');
export const getApiName = (obj) => getProp(obj, 'apiName');

export default getProp;
