import React from 'react';

const FormIndent: React.FC = (props) => {
  return (
    <svg width='25' height='53' viewBox='0 0 25 53' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M1 0V44C1 48.4183 4.58172 52 9 52H25' stroke='#0F1226' strokeOpacity='0.25' />
    </svg>
  );
};

export default FormIndent;
