import React, { useEffect, useMemo, useState } from 'react';
import { FormikHelpers } from 'formik';
import { connect, ConnectedProps } from 'react-redux';

import { StandardModal } from '~/components/shared/modal';
import { updateCollaboration } from '~/ducks/admin/collaborations';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { Client, Collaboration } from '~/models';
import { ClientOptions } from '~/models/Client';
import { adminClientsApi } from '~/services/api';

import CollaborationForm, { CollaborationFormData } from './CollaborationForm';

const mapDispatchToProps = {
  addToast,
  updateCollaboration,
};

const connector = connect(null, mapDispatchToProps);

type CollaborationModalProps = ConnectedProps<typeof connector> & {
  client: Client | Collaboration;
  onCancel: () => void;
  show?: boolean;
};

function CollaborationModal({ client, onCancel, show, addToast, updateCollaboration }: CollaborationModalProps) {
  const collaboration = useMemo(() => new Collaboration(client), [client]);
  const [clients, setClients] = useState<Client[]>([]);

  const fetchClients = async () => {
    const params = {
      'id.not': client.id,
      pageSize: 1000,
      sortBy: 'name',
    };

    const res = await adminClientsApi.fetch.invoke(params);

    setClients(res.data.data.map((item: ClientOptions) => new Client(item)));
  };

  useEffect(() => {
    if (show) fetchClients();
  }, [collaboration, show]);

  const handleSubmit = (
    formValues: CollaborationFormData,
    { setErrors, setSubmitting }: FormikHelpers<CollaborationFormData>
  ) => {
    const data = Collaboration.fromFormValues(formValues).serialize();

    updateCollaboration(data)
      .then(unwrapResult)
      .then(() => {
        addToast({ text: 'Collaborations successfully updated' });
        onCancel();
      })
      .catch((e) => {
        if (e?.response?.data?.errors?.client) {
          setErrors({ collaborators: e?.response?.data?.errors?.client.join(', ') });
        } else {
          addToast({ text: 'There was an error updating the collaborations' });
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <StandardModal show={show} title='Client Collaborations' onCancel={onCancel} disableBackdropClick>
      <CollaborationForm {...{ clients, collaboration, onCancel, onSubmit: handleSubmit }} />
    </StandardModal>
  );
}

export default connector(CollaborationModal);
