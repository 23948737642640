import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { getProfile } from '~/ducks/profile';
import { useSwitch } from '~/lib/hooks';
import { Profile } from '~/models';
import { ProfileContext, ProfileService } from '~/services/profile';

import HeaderNavbar from '../layouts/HeaderNavbar';
import SideNavbar from '../layouts/SideNavbar';
import Toasts from '../shared/toasts/toasts';

import ClientSelectModal from './ClientSelectModal';

const mapStateToProps = (state: any) => ({
  profile: getProfile(state),
});

const connector = connect(mapStateToProps);

type HomeProps = ConnectedProps<typeof connector>;

const Home: React.FC<HomeProps> = (props) => {
  const { profile } = props;
  const isProfileLoaded = !!profile.id;
  const [profileSvc, setProfileSvc] = useState(new ProfileService(profile));
  const {
    state: showClientSelectModal,
    turnOff: turnOffClientSelectModal,
    turnOn: turnOnClientSelectModal,
  } = useSwitch();

  const identifyUser = (userProfile: Profile) => {
    // send user data for gtm and appcues
    const w = window as any;

    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push({
      event: 'profile updated',
      user: {
        id: userProfile.id,
        email: userProfile.email,
        name: userProfile.name,
        client: userProfile.client.name,
        clientType: userProfile.client.clientType,
        providedProviderTypes: userProfile.providedProviderTypes?.map((type) => type.apiName),
        managedProviderTypes: userProfile.managedProviderTypes?.map((type) => type.apiName),
        role: userProfile.role,
        groupType: userProfile.groupType,
        userType: userProfile.isAcute ? 'acute' : 'post-acute',
        createdAtDate: userProfile.createdAt,
        lastLogin: userProfile.lastLogin,
        actingClient: userProfile.actingClient.name,
      },
    });
  };

  useEffect(() => {
    const profileService = new ProfileService(profile);

    Sentry.setContext('login-info', {
      date: new Date(),
    });
    Sentry.setUser({ id: profileService.profile.id });

    datadogRum.setUser({
      id: profileService.profile.id,
      email: profileService.profile.email,
      clientId: profileService.client.id,
      clientName: profileService.client.name,
      actingClientId: profileService.actingClient.id,
      actingClientName: profileService.actingClient.name,
    });

    identifyUser(profileService.profile);

    setProfileSvc(profileService);
  }, [profile]);

  if (!isProfileLoaded) {
    return <CircleSpinner centered />;
  }

  return (
    <ProfileContext.Provider value={profileSvc}>
      <div>
        <HeaderNavbar onSelectClientClick={turnOnClientSelectModal} />
        <ClientSelectModal isOpen={showClientSelectModal} onCancel={turnOffClientSelectModal} />
        <MainContainer>
          <Toasts />
          <SideNavbar />
          <ContentContainer id='mainScrollContainer'>
            <Outlet />
          </ContentContainer>
        </MainContainer>
      </div>
    </ProfileContext.Provider>
  );
};

export default connector(Home);

const mainHeaderNavHeight = 48;
const MainContainer = styled.div.attrs({
  headerNavHeight: mainHeaderNavHeight,
})`
  display: flex;
  min-height: calc(100vh - ${({ headerNavHeight }) => headerNavHeight + 'px'});
`;

const ContentContainer = styled.div.attrs({
  headerNavHeight: mainHeaderNavHeight,
})`
  flex: 1;
  background: ${({ theme }) => theme.colors.black05};
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - ${({ headerNavHeight }) => headerNavHeight + 'px'});
  position: relative;
`;
