import {
  ACUTE_CLIENT_TYPES,
  ADMIN,
  AFFILIATE,
  HEALTH_SYSTEM,
  INPATIENT_POST_ACUTE_CARE,
  OUTPATIENT_POST_ACUTE_CARE,
  OWNER_CLIENT_TYPES,
  PAYOR,
  PHYSICIAN_GROUP,
  POST_ACUTE_CLIENT_TYPES,
} from '~/constants/clientTypes';

import Classification from './Classification';
import ClientGroupType from './ClientGroupType';
import Group from './Group';
import GroupType, { GroupTypeOptions } from './GroupType';

export const MFA_FACTORS: { [key: string]: { independent: boolean; label: string } } = {
  email: {
    independent: false,
    label: 'Email',
  },
  'phone:sms': {
    independent: true,
    label: 'Phone (SMS)',
  },
  otp: {
    independent: true,
    label: 'One Time Password',
  },
  'webauthn-platform': {
    independent: false,
    label: 'Biometrics',
  },
  'webauthn-roaming': {
    independent: true,
    label: 'Hardware Key',
  },
};

export interface MfaConfigOptions {
  enabled: boolean;
  allowedFactors: string[];
  default: null | string;
}

function getMfaDefaults(): MfaConfigOptions {
  return {
    enabled: false,
    allowedFactors: [],
    default: null,
  };
}

export enum ClientType {
  ADMIN = 'Admin',
  AFFILIATE = 'Affiliate',
  HEALTH_SYSTEM = 'Health System',
  INPATIENT_POST_ACUTE_CARE = 'Inpatient Post Acute Care',
  OUTPATIENT_POST_ACUTE_CARE = 'Outpatient Post Acute Care',
  PAYOR = 'Payor',
  PHYSICIAN_GROUP = 'Physician Group',
}

export const filterAllowedFactorsByIndependence = (val: boolean, collection: string[]) =>
  collection
    .filter((factorName) => MFA_FACTORS[factorName].independent === val)
    .map((factorName) => ({ id: factorName, name: MFA_FACTORS[factorName].label }));

export class MfaConfig implements MfaConfigOptions {
  enabled: boolean;
  allowedFactors: string[];
  default: null | string;

  constructor(options: Partial<MfaConfigOptions> = {}) {
    const opts = { ...getMfaDefaults(), ...options };

    this.enabled = opts.enabled || false;
    this.allowedFactors = opts.allowedFactors;
    this.default = opts.default;
  }

  toFormValues() {
    return {
      ...this,
      default: this.default ? { id: this.default, name: MFA_FACTORS[this.default]?.label } : null,
      allowedIndependentFactors: filterAllowedFactorsByIndependence(true, this.allowedFactors),
      allowedDependentFactors: filterAllowedFactorsByIndependence(false, this.allowedFactors),
    };
  }
}

export interface ClientOptions {
  id: string;
  children: any[];
  clientType: ClientType;
  clientGroupTypes: any[];
  collaboratorIds: any[];
  episodeClassifications: any[];
  enabledProviderTypes: Partial<GroupTypeOptions>[];
  providedProviderTypes: Partial<GroupTypeOptions>[];
  managedProviderTypes: Partial<GroupTypeOptions>[];
  groupTypeDisplayNames: any;
  groupTypes: Partial<GroupTypeOptions>[];
  leaf: boolean;
  leafDescendants: any[];
  mfaConfig: MfaConfigOptions;
  name: string;
  parent: any;
  parentId: any;
  planTypeClassifications: any[];
  ssoEnabled: boolean;
  enabledFlags: string[];
  fileRetentionDays: number | null;
}

function getDefaults() {
  return {
    id: '',
    children: [],
    clientType: '' as ClientType,
    clientGroupTypes: [],
    collaboratorIds: [],
    enabledProviderTypes: [],
    providedProviderTypes: [],
    managedProviderTypes: [],
    enabledFlags: [],
    episodeClassifications: [],
    episodeClassificationIds: [],
    groupTypeDisplayNames: null,
    groupTypes: [],
    leaf: true,
    leafDescendants: [],
    mfaConfig: {
      enabled: false,
      allowedFactors: [],
      default: null,
    },
    name: '',
    parent: null,
    parentId: null,
    planTypeClassifications: [],
    planTypeClassificationIds: [],
    ssoEnabled: false,
    fileRetentionDays: null,
    networkGroups: [],
  };
}

export default class Client implements ClientOptions {
  id: string;
  children: Client[];
  clientType: ClientType;
  collaboratorIds: string[];
  clientGroupTypes: ClientGroupType[];
  enabledProviderTypes: GroupType[];
  providedProviderTypes: GroupType[];
  managedProviderTypes: GroupType[];
  enabledFlags: string[];
  episodeClassifications: Classification[];
  groupTypeDisplayNames: null | string;
  groupTypes: GroupType[];
  leaf: boolean;
  leafDescendants: Client[];
  mfaConfig: MfaConfig;
  name: string;
  parent: Client | null;
  parentId: string | null;
  planTypeClassifications: Classification[];
  ssoEnabled: boolean;
  fileRetentionDays: number | null;
  networkGroups: Group[];

  constructor(options = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.children = opts.children.map((child) => new Client(child));
    this.clientType = opts.clientType;
    this.collaboratorIds = opts.collaboratorIds;
    this.clientGroupTypes = opts.clientGroupTypes.map((cgt) => new ClientGroupType(cgt));
    this.enabledProviderTypes = opts.enabledProviderTypes.map((ept) => new GroupType(ept));
    this.providedProviderTypes = opts.providedProviderTypes.map((ept) => new GroupType(ept));
    this.managedProviderTypes = opts.managedProviderTypes.map((ept) => new GroupType(ept));
    this.enabledFlags = opts.enabledFlags;
    this.episodeClassifications = opts.episodeClassifications.map((ep) => new Classification(ep));
    this.groupTypeDisplayNames = opts.groupTypeDisplayNames;
    this.groupTypes = opts.groupTypes.map((gt) => new GroupType(gt));
    this.leaf = opts.leaf;
    this.leafDescendants = opts.leafDescendants.map((child) => new Client(child));
    this.mfaConfig = new MfaConfig(opts.mfaConfig);
    this.name = opts.name;
    this.parent = opts.parent && new Client(opts.parent);
    this.parentId = opts.parentId;
    this.planTypeClassifications = opts.planTypeClassifications.map((p) => new Classification(p));
    this.ssoEnabled = opts.ssoEnabled;
    this.fileRetentionDays = opts.fileRetentionDays;
    this.networkGroups = opts.networkGroups.map((ng) => new Group(ng));
  }

  get isAcute() {
    return ACUTE_CLIENT_TYPES.includes(this.clientType);
  }

  get isPostAcute() {
    return POST_ACUTE_CLIENT_TYPES.includes(this.clientType);
  }

  get isAdmin() {
    return this.clientType === ADMIN;
  }

  get isAffiliate() {
    return this.clientType === AFFILIATE;
  }

  get isHealthSystem() {
    return this.clientType === HEALTH_SYSTEM;
  }

  get isInpatientPostAcute() {
    return this.clientType === INPATIENT_POST_ACUTE_CARE;
  }

  get isOutpatientPostAcute() {
    return this.clientType === OUTPATIENT_POST_ACUTE_CARE;
  }

  get isOwningClientType() {
    return OWNER_CLIENT_TYPES.includes(this.clientType);
  }

  get isPayor() {
    return this.clientType === PAYOR;
  }

  get isParent() {
    return !this.leaf;
  }

  get isPhysicianGroup() {
    return this.clientType === PHYSICIAN_GROUP;
  }

  get leafDescendantIds() {
    return this.leafDescendants.map((leaf) => leaf.id);
  }

  get providerGroupTypes() {
    return this.groupTypes.filter((groupType) => groupType.type.apiName === 'provider');
  }

  get groupTypeIds() {
    return this.groupTypes.map((groupType) => groupType.id);
  }

  get mfaEnabled() {
    return this.mfaConfig.enabled;
  }

  toFormValues() {
    return {
      ...this,
      clientType: this.clientType ? { name: this.clientType, kind: this.clientType } : null,
      mfaConfig: this.mfaConfig.toFormValues(),
      // Arranged by group type
      networkGroups: this.networkGroups.reduce(
        (acc, group: Group) => {
          if (!acc[group.groupType.id]) {
            const { id, displayName } = group.groupType;

            acc[group.groupType.id] = { groupTypeId: id, groupTypeDisplayName: displayName, groups: [] };
          }

          acc[group.groupType.id].groups.push(group);

          return acc;
        },
        {} as Record<string, { groupTypeId: string; groupTypeDisplayName: string; groups: Group[] }>
      ),
    };
  }

  configForGroupType(groupTypeApiNameOrId: string) {
    return this.clientGroupTypes.find((clientGroupType) =>
      [clientGroupType.groupType?.apiName, clientGroupType.groupTypeId].includes(groupTypeApiNameOrId)
    )?.config;
  }
}
