import React, { useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { updateNavigation } from '~/ducks/navigation';
import { fetchProfile, updateProfile } from '~/ducks/profile';
import { Profile } from '~/models';
import { authenticationFlow, authService } from '~/services/auth';

import theme from '../styles/theme';

import { SessionMonitor } from './auth';
import { Home } from './home';

const connector = connect(null, {
  fetchProfile,
  updateProfile,
  updateNavigation,
});

type AppProps = ConnectedProps<typeof connector>;

function App(props: AppProps) {
  const location = useLocation();
  const navigate = useNavigate();
  // ensure initial authentication has completed before running other useEffect functions
  const [initialized, setInitialized] = useState(false);

  // This is the main spot for triggering our entire authentication flow.
  useEffect(() => {
    const initialize = async () => {
      const { flow } = await authService.authenticate(location.pathname);

      if (flow === authenticationFlow.error) {
        return navigate('/error');
      }

      const authenticated = await authService.isAuthenticated();

      if (authenticated) {
        const response = await props.fetchProfile({
          include:
            'client.leaf_descendants,preferences,acting_client.group_types,enabled_provider_types,selected_group_ids,allowed_group_ids',
        });
        const profile = new Profile(response.payload);
        const profileUpdateParams: {
          lastLogin?: Date;
          actingClientId?: string;
          include?: string;
        } = {};
        const isLoginRedirectCallback = flow === authenticationFlow.loginRedirectCallback;

        if (isLoginRedirectCallback) {
          profileUpdateParams.lastLogin = new Date();
        }

        // automatically set acting client if you have child clients and no acting client set
        if (!profile.actingClientId && profile.client.leafDescendants.length) {
          profileUpdateParams.actingClientId = profile.client.leafDescendantIds[0];
        }

        if (Object.keys(profileUpdateParams).length > 0) {
          profileUpdateParams.include = 'acting_client.group_types,preferences,selected_group_ids,allowed_group_ids';

          await props.updateProfile(profileUpdateParams);
        }

        if (isLoginRedirectCallback) {
          navigate(authService.redirectPath, {
            state: { initialLogin: true },
          });
        }

        setInitialized(true);
      }

      return null;
    };

    initialize();
  }, []);

  // This is the main spot for checking whether the current user's session is active.
  // The logic used to live in ProtectedRoute but it is elevated up to the root level now.
  useEffect(() => {
    const checkSession = async () => {
      try {
        await authService.renewSession();
      } catch (e) {
        authService.logout();
      }
    };

    if (initialized) {
      checkSession();
    }
  }, [initialized, location]);

  const previousPathname = useRef<string | null>(null);

  useEffect(() => {
    (props.updateNavigation as any)({
      current: location.pathname,
      previous: previousPathname.current,
    });
    previousPathname.current = location.pathname;
  }, [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      {initialized ? <Home /> : <CircleSpinner centered />}
      <SessionMonitor />
    </ThemeProvider>
  );
}

export default connector(App);
