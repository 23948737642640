import { Profile } from '~/models';

export const PERMISSIONS = {
  adminAttrView: 'adminAttrView' as const,
  adminAttrCreate: 'adminAttrCreate' as const,
  adminAttrEdit: 'adminAttrEdit' as const,
  adminAttrValueView: 'adminAttrValueView' as const,
  adminAttrValueCreate: 'adminAttrValueCreate' as const,
  adminAttrValueEdit: 'adminAttrValueEdit' as const,
  adminClassificationCreate: 'adminClassificationCreate' as const,
  adminClassificationEdit: 'adminClassificationEdit' as const,
  adminClassificationView: 'adminClassificationView' as const,
  adminClientCreate: 'adminClientCreate' as const,
  adminClientEdit: 'adminClientEdit' as const,
  adminClientView: 'adminClientView' as const,
  adminClientGroupTypeEdit: 'adminClientGroupTypeEdit' as const,
  adminClientGroupTypeView: 'adminClientGroupTypeView' as const,
  adminCollaborationEdit: 'adminCollaborationEdit' as const,
  adminCollaborationView: 'adminCollaborationView' as const,
  adminDischargeReasonCreate: 'adminDischargeReasonCreate' as const,
  adminDischargeReasonEdit: 'adminDischargeReasonEdit' as const,
  adminDischargeReasonView: 'adminDischargeReasonView' as const,
  adminFeatureFlagEdit: 'adminFeatureFlagEdit' as const,
  adminFeatureFlagView: 'adminFeatureFlagView' as const,
  adminGroupCreate: 'adminGroupCreate' as const,
  adminGroupDelete: 'adminGroupDelete' as const,
  adminGroupEdit: 'adminGroupEdit' as const,
  adminGroupView: 'adminGroupView' as const,
  adminGroupTypeCreate: 'adminGroupTypeCreate' as const,
  adminGroupTypeEdit: 'adminGroupTypeEdit' as const,
  adminGroupTypeView: 'adminGroupTypeView' as const,
  adminImportedPatientEdit: 'adminImportedPatientEdit' as const,
  adminImportedPatientView: 'adminImportedPatientView' as const,
  adminImportConfigurationCreate: 'adminImportConfigurationCreate' as const,
  adminImportConfigurationEdit: 'adminImportConfigurationEdit' as const,
  adminImportConfigurationView: 'adminImportConfigurationView' as const,
  adminQuestionTemplateCreate: 'adminQuestionTemplateCreate' as const,
  adminQuestionTemplateView: 'adminQuestionTemplateView' as const,
  adminQuestionTemplateEdit: 'adminQuestionTemplateEdit' as const,
  adminUserCreate: 'adminUserCreate' as const,
  adminUserEdit: 'adminUserEdit' as const,
  adminUserInvitationCreate: 'adminUserInvitationCreate' as const,
  adminUserDelete: 'adminUserDelete' as const,
  adminUserView: 'adminUserView' as const,
  activityNoteCreate: 'activityNoteCreate' as const,
  activityProgressUpdateCreate: 'activityProgressUpdateCreate' as const,
  escalationCreate: 'escalationCreate' as const,
  escalationEdit: 'escalationEdit' as const,
  locationEpisodeUserEdit: 'locationEpisodeUserEdit' as const,
  patientCreate: 'patientCreate' as const,
  patientDelete: 'patientDelete' as const,
  patientEdit: 'patientEdit' as const,
  rehabStateAdmissionCreate: 'rehabStateAdmissionCreate' as const,
  rehabStateDischargeCreate: 'rehabStateDischargeCreate' as const,
  rehabStateEdit: 'rehabStateEdit' as const,
  reviewAuthorizationEdit: 'reviewAuthorizationEdit' as const,
  reviewProjectedDischargeEdit: 'reviewProjectedDischargeEdit' as const,
  reviewServiceRefusalCreate: 'reviewServiceRefusalCreate' as const,
  reviewServiceRefusalEdit: 'reviewServiceRefusalEdit' as const,
  reviewAltcsApplicationEdit: 'reviewAltcsApplicationEdit' as const,
};

export default class ProfileService {
  profile: Profile;

  constructor(profile = new Profile()) {
    this.profile = profile;
  }

  get actingClient() {
    return this.profile.actingClient;
  }

  get client() {
    return this.profile.client;
  }

  get clientType() {
    return this.actingClient?.clientType;
  }

  get enabledProviderTypes() {
    return this.profile.enabledProviderTypes;
  }

  get managedProviderTypes() {
    return this.profile.managedProviderTypes;
  }

  get providedProviderTypes() {
    return this.profile.providedProviderTypes;
  }

  get isAcute() {
    return this.profile.isAcute;
  }

  get isPostAcute() {
    return !this.isAcute;
  }

  get isAdmin() {
    return this.profile.isAdmin;
  }

  get isInpatientPostAcute() {
    return this.profile.isInpatientPostAcute;
  }

  get isOutpatientPostAcute() {
    return this.profile.isOutpatientPostAcute;
  }

  get groupType() {
    return this.profile.groupType;
  }

  has(permission: keyof typeof PERMISSIONS) {
    if (!this.profile.permissions) return false;

    return (this.profile.permissions as { [key in keyof typeof PERMISSIONS]: boolean })[permission] || false;
  }

  hasAll(permissions: (keyof typeof PERMISSIONS)[]) {
    return permissions.every((permission) => this.has(permission));
  }

  hasAny(permissions: (keyof typeof PERMISSIONS)[]) {
    return permissions.some((permission) => this.has(permission));
  }

  hasFlag(name: string) {
    if (!this.profile.flags) return false;

    return (this.profile.flags as string[]).includes(name);
  }
}
