import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ControlledTable } from '~/components/shared/table';
import { clearClients, fetchClients, getClients, getClientsLoaded, getClientsPageCount } from '~/ducks/admin/clients';
import { clearFilters, getClientsFilters, getClientsFiltersForRequest, setFilter } from '~/ducks/clientsFilters';
import { useModel } from '~/lib/hooks';
import { Client } from '~/models';
import { useProfileContext } from '~/services/profile';

import clientsTableColumns from './clientsTableColumns';

const mapStateToProps = (state: any) => ({
  clients: getClients(state),
  filters: getClientsFilters(state),
  filtersForRequest: getClientsFiltersForRequest(state),
  pageCount: getClientsPageCount(state),
  loaded: getClientsLoaded(state),
});

const mapDispatchToProps = {
  clearClients,
  clearFilters,
  fetchClients,
  setFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ClientsTableProps = ConnectedProps<typeof connector> & {
  onCollaborationEdit: (client: Client) => void;
};

function ClientsTable({
  filters,
  filtersForRequest,
  loaded,
  onCollaborationEdit,
  pageCount,
  ...props
}: ClientsTableProps) {
  const navigate = useNavigate();
  const profileSvc = useProfileContext();

  const [pagingFilters, setPagingFilters] = useState<Partial<Record<string, any>>>({});
  const debouncedFetchClients = useCallback(
    _.debounce((params) => props.fetchClients(params), 50),
    []
  );
  const clients = useModel(Client, props.clients);

  useEffect(() => {
    props.clearClients();
    debouncedFetchClients({
      ...pagingFilters,
      ...filtersForRequest,
      include: 'managedProviderTypes',
    });
  }, [pagingFilters, filtersForRequest]);

  const handlePagingFiltersChange = useCallback((newPagingFilters: object) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const handleEditClient = useCallback((client: Client) => {
    navigate(`/clients/${client.id}/edit`);
  }, []);

  const handleEditClientGroupTypes = useCallback((client: Client) => {
    navigate(`/clients/${client.id}/group-types/edit`);
  }, []);

  const columns = useMemo(
    () =>
      clientsTableColumns({
        profileSvc,
        onEdit: handleEditClient,
        onEditClientGroupTypes: handleEditClientGroupTypes,
        onCollaborationEdit,
      }),
    []
  );

  return (
    <ControlledTable
      data={clients}
      defaultSortBy={'name asc'}
      loading={!loaded}
      columns={columns}
      filters={filters}
      onPagingFiltersChange={handlePagingFiltersChange}
      pageCount={pageCount}
    />
  );
}

export default connector(ClientsTable);
